.transaction-main-container {
  height: 100%;

  .tmc-title {
    color: $theme-blue;

    .add-trans-btn {
      padding: 0.4em 0.8em;
      color: $White-Color;
      background-color: $theme-blue;
    }
  }
  .tmc-table-container {
    height: 84%;
    overflow: auto;
  }
  .transaction-row {
    display: flex;
    align-items: center;
    @media screen and (max-width: 992px) {
      width: 980px;
    }
  }
  .transaction-row-container {
    height: 78%;
    overflow-y: auto;
    @media screen and (max-width: 992px) {
      width: 1000px;
    }
  }
  .row-padding {
    padding: 10px 5px;
  }
  .thr-content {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    .two-arrow {
      height: 0.6em;
    }

    .light-blue-btn {
      width: 2.5em;
      height: 2.5em;
      border-radius: 0.3em;

      img {
        width: 1em;
      }
    }
  }
  .header-row {
    background-color: $theme-light-blue;
    color: $theme-subuser;
    .header-content {
      padding: 20px 5px;
    }
    .sort-arrow {
      height: 8px;
      width: auto;
      margin-left: 5px;
      cursor: pointer;
    }
  }
  .thr-15 {
    width: 15%;
  }

  .thr-30 {
    width: 30%;
  }

  .thr-10 {
    width: 10%;
  }

  .thr-20 {
    width: 20%;
  }

  .thr-40 {
    width: 40%;
  }

  .view-down {
    font-size: 10px;
    color: $theme-blue;
    .view-img {
      height: 1em;
      margin: 0 5px;
    }
  }

  .tran-status {
    color: $White-Color;
    // padding: 1em 1.5em;
    height: 2.5em;
    width: 8em;
    border-radius: 0.4em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .status-completed {
    background-color: rgba(0, 200, 81, 0.1);
    color: #00c851;
  }
  .status-authorised {
    background-color: #dde8f6;
    color: #133f7d;
  }
  .status-pending {
    background-color: #e7e7e7 !important;
    color: #626262;
  }
  .status-failed {
    background: rgba(242, 70, 75, 0.1);
    color: #f2464b;
  }

  .payment-btn {
    height: 3em;
    width: 15em;
  }

  .pay-content {
    height: 6em;
    display: flex;
    align-items: center;
    img {
      height: 2em;
    }
  }
}

//invoice css//
.invoice-container {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1000;
  overflow-y: auto;

  .invoice {
    position: absolute;
    top: 0;
    width: 595px;
    background-color: white;
    padding: 25px 20px 0px 20px;
    display: flex;
    flex-direction: column;

    .invoice-close {
      position: absolute;
      top: 1%;
      right: 1%;
      height: 5px;
      width: 5px;
    }

    .grey-text {
      color: $theme-subuser;
    }

    .tr-border {
      border-bottom: 0.5px solid #6f6f84;
    }

    .invoice-header {
      display: flex;
      justify-content: space-between;
      color: #6f6f84;

      .invoice-logo {
        width: 170px;
        height: 80px;
      }

      .company-name {
        margin: 0;
      }
      .company-address {
        display: flex;
        flex-direction: column;
        text-align: end;
        * {
          margin: 0;
        }
      }
    }

    .invoice-main-content {
      .company-details {
        border-bottom: 0.5px solid $theme-subuser;
        padding-bottom: 10px;
        margin-bottom: 10px;
        color: #6f6f84;

        * {
          margin: 0;
        }

        display: flex;

        .part-title {
          color: #6f6f84;
          font-size: 14px;
        }

        .part-1 {
          width: 35%;
        }

        .part-2 {
          width: 20%;
          text-align: end;
        }
      }

      .product-details {
        margin-bottom: 30px;

        span,
        .invoice-table-header {
          color: $theme-subuser;
        }

        td {
          padding: 5px 0;
        }
      }

      .total-container {
        table {
          width: 35%;

          td {
            padding: 2px 0;
          }
        }
      }
    }

    .invoice-table-header {
      color: #6f6f84;
    }
    .additional-info {
      color: #6f6f84;
    }
    .auth {
      color: #6f6f84;
      margin-top: 20px;
    }

    .invoice-footer {
      * {
        margin: 0;
      }

      color: #6f6f84;
    }
  }
}

//invoice css//
.invoice-container-v2 {
  position: absolute;
  left: 0;
  top: -2000px;
  width: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  z-index: -1;
  overflow-y: auto;
  font-family: "Manrope", sans-serif;

  .invoice {
    top: 0;
    width: 700px;
    background-color: white;
    padding: 25px 20px 0px 20px;
    display: flex;
    flex-direction: column;

    .grey-text {
      color: $theme-subuser;
    }

    .tr-border {
      border-bottom: 0.5px solid #6f6f84;
    }

    .invoice-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      color: #6f6f84;

      .invoice-logo {
        width: 170px;
        height: 80px;
      }

      .company-name {
        margin: 0;
      }

      .company-address {
        display: flex;
        flex-direction: column;
        text-align: end;

        * {
          margin: 0;
        }
      }
    }

    .invoice-main-content {
      .company-details {
        border-bottom: 0.5px solid $theme-subuser;
        padding-bottom: 10px;
        margin-bottom: 10px;

        * {
          margin: 0;
        }

        display: flex;

        .part-title {
          color: #6f6f84;
          font-size: 14px;
        }

        .part-1 {
          width: 35%;
        }

        .part-2 {
          width: 35%;
          text-align: end;
        }
      }

      .product-details {
        margin-bottom: 30px;

        span,
        .invoice-table-header {
          color: $theme-subuser;
        }

        td {
          padding: 5px 0;
        }
      }

      .total-container {
        table {
          width: 35%;

          td {
            padding: 2px 0;
          }
        }
      }
    }

    .invoice-table-header {
      color: #6f6f84;
    }

    .additional-info {
      color: #6f6f84;
    }

    .auth {
      color: #6f6f84;
      margin-top: 20px;
    }

    .invoice-footer {
      * {
        margin: 0;
      }
      color: #6f6f84;
    }
  }
}

//invoice css//
.interviewer-invoice-container {
  .invoice {
    position: absolute;
    top: 0;
    width: 595px;
    background-color: white;
    padding: 25px 20px 0px 20px;
    display: flex;
    flex-direction: column;

    .invoice-close {
      position: absolute;
      top: 1%;
      right: 1%;
      height: 5px;
      width: 5px;
    }
    .invoice-title {
      color: #133f7d;
      font-family: "Manrope-Bold" !important;
    }
    .grey-text {
      color: $theme-subuser;
    }

    .tr-border {
      border-bottom: 0.5px solid #6f6f84;
    }

    .invoice-header {
      display: flex;
      justify-content: space-between;
      color: #6f6f84;

      .invoice-logo {
        width: 170px;
        height: 80px;
      }

      .company-name {
        margin: 0;
      }
      .company-address {
        display: flex;
        flex-direction: column;
        text-align: end;
        * {
          margin: 0;
        }
      }
    }

    .invoice-main-content {
      .company-details {
        border-bottom: 0.5px solid $theme-subuser;
        padding-bottom: 10px;
        margin-bottom: 10px;
        color: #6f6f84;

        * {
          margin: 0;
        }

        display: flex;

        .part-title {
          color: #6f6f84;
          font-size: 14px;
        }

        .part-1 {
          width: 35%;
        }

        .part-2 {
          width: 20%;
          text-align: end;
        }
      }

      .product-details {
        margin-bottom: 30px;

        span,
        .invoice-table-header {
          color: $theme-subuser;
        }

        td {
          padding: 5px 0;
        }
      }

      .total-container {
        table {
          width: 35%;

          td {
            padding: 2px 0;
          }
        }
      }
    }

    .invoice-table-header {
      color: #6f6f84;
    }
    .additional-info {
      color: #6f6f84;
    }
    .auth {
      color: #6f6f84;
      margin-top: 20px;
    }

    .invoice-footer {
      * {
        margin: 0;
      }

      color: #6f6f84;
    }
  }
}
