.evpCard {
  position: relative;
  background-color: $White-Color;
  padding: 10px 22px;
  border-radius: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  @media screen and (max-width: 992px) {
    flex-direction: column;
    width: auto;
  }
  .evp-left {
    height: 60px;
    width: 60px;
    overflow: hidden;
    border-radius: 50%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  .evp-profile {
    height: 100%;
    width: auto;
    // object-fit: cover;
    // object-position: top center;
  }
  .profile-btn {
    width: 100px;
    height: 25px;
    margin-right: 10px;
    background: $theme-light-blue;
    color: $theme-blue;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .evp-rightv2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    .evp-product {
      margin: 0 10px;
      font-size: 12px;
      color: $theme-blue;
      .subscribed-product {
        width: 30px;
        height: auto;
      }
    }
  }
  .evp-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    right: 15px;
    position: absolute;
    @media screen and (max-width: 992px) {
      flex-direction: row;
    }
    .evp-btn1,
    .evp-btn2,
    .evp-btn3 {
      height: 28px;
      width: 162px;
      padding: 0;
      font-size: 12px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
      border: none;
      cursor: pointer;
      &:disabled {
        background-color: #e7e7e7;
        color: #626262;
        cursor: not-allowed;
      }
    }

    .evp-btn1 {
      color: $theme-blue;
      background-color: $theme-light-blue;
      margin-bottom: 4px;
      @media screen and (max-width: 992px) {
        margin: 5px;
      }
    }

    .evp-btn3 {
      background-color: rgba(242, 70, 75, 0.15);
      color: #f2464b;
      margin-top: 4px;
      @media screen and (max-width: 992px) {
        margin: 5px;
      }
    }

    .evp-btn2 {
      color: $theme-peacock;
      background-color: $theme-light-peacock;
      margin-top: 4px;
      @media screen and (max-width: 992px) {
        margin: 5px;
      }
    }
  }
  .interviewer-card {
    width: calc(100% - 230px);
    padding: 0px 20px;
    .evp-m-title {
      .client-evp-status {
        padding: 5px 10px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        margin-left: 10px;
        .status-dot {
          height: 6px;
          width: 6px;
          border-radius: 50%;
          margin-right: 5px;
        }
      }
      .red-signal {
        background-color: rgba(242, 70, 75, 0.15);
        color: #f2464b;
        .status-dot {
          background-color: #f2464b;
        }
      }
      .gray-signal {
        background-color: #f0f0f2;
        color: #626262;
        .status-dot {
          background-color: #626262;
        }
      }
      .status {
        width: 120px;
        height: 20px;
        border-radius: 5px;
        margin-left: 25px;
      }
      .status-delete {
        background: $theme-light-red-bg;
        color: $theme-red;
      }
      .status-active {
        background-color: rgba(0, 200, 81, 0.1);
        color: #00c851;
      }
      .status-inactive {
        background-color: #e7e7e7 !important;
        color: #626262;
      }
      .status-suspended {
        background: #9b9b9b;
        color: white;
      }

      @media screen and (max-width: 992px) {
        text-align: center;
        flex-direction: column;
      }
    }
    .evp-m-divider {
      background-color: $theme-light-gray;
      height: 1px;
      margin: 5px 0;
      @media screen and (max-width: 992px) {
        margin: 0.6em 0;
      }
    }
    .evp-m-subtitle {
      font-size: 12px;
      color: $theme-placeholder;
      @media screen and (max-width: 992px) {
        flex-direction: column;
        align-items: center;
      }
      .evp-m-sub {
        display: flex;
        align-items: center;
        overflow-wrap: anywhere;
        margin-right: 13px;
        @media screen and (max-width: 992px) {
          margin: 5px 0;
        }
      }
      img {
        height: 1.5em;
        margin-right: 5px;
      }
      @media screen and (max-width: 767px) {
        justify-content: center;
      }
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      text-align: center;
    }
  }

  .evp-middle {
    width: calc(100% - 300px);
    padding: 0px 20px;
    .evp-m-title {
      .client-evp-status {
        padding: 5px 10px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        margin-left: 10px;
        .status-dot {
          height: 6px;
          width: 6px;
          border-radius: 50%;
          margin-right: 5px;
        }
      }
      .red-signal {
        background-color: rgba(242, 70, 75, 0.15);
        color: #f2464b;
        .status-dot {
          background-color: #f2464b;
        }
      }
      .green-signal {
        background-color: rgb(24 172 0 / 8%);
        color: #18ac00;
        .status-dot {
          background-color: #18ac00;
        }
      }
      .gray-signal {
        background-color: #f0f0f2;
        color: #626262;
        .status-dot {
          background-color: #626262;
        }
      }
      .status {
        width: 120px;
        height: 20px;
        border-radius: 5px;
        margin-left: 25px;
      }
      .status-delete {
        background: $theme-light-red-bg;
        color: $theme-red;
      }
      .status-active {
        background-color: rgba(0, 200, 81, 0.1);
        color: #00c851;
      }
      .status-inactive {
        background-color: #e7e7e7 !important;
        color: #626262;
      }
      .status-suspended {
        background: #9b9b9b;
        color: white;
      }

      @media screen and (max-width: 992px) {
        text-align: center;
        flex-direction: column;
      }
    }
    .evp-m-divider {
      background-color: $theme-light-gray;
      height: 1px;
      margin: 5px 0;
      @media screen and (max-width: 992px) {
        margin: 0.6em 0;
      }
    }
    .evp-m-subtitle {
      font-size: 12px;
      color: $theme-placeholder;
      @media screen and (max-width: 992px) {
        flex-direction: column;
        align-items: center;
      }
      .evp-m-sub {
        display: flex;
        align-items: center;
        overflow-wrap: anywhere;
        margin-right: 20px;
        @media screen and (max-width: 992px) {
          margin: 5px 0;
        }
      }
      img {
        height: 1.5em;
        margin-right: 5px;
      }
      @media screen and (max-width: 767px) {
        justify-content: center;
      }
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      text-align: center;
    }
  }
}
